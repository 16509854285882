<template>
  <div class="content">
    <div class="search_view">
      <div class="btn_view" @click="addPopup.show = true">新增银行账号</div>
      <div class="btn_view" @click="upPopup.show = true">修改企业信息</div>
    </div>
    <el-table :data="tableAccountData" stripe style="width: 100%">
      <el-table-column prop="id" label="序号" width="56" v-if="false"></el-table-column>
      <el-table-column prop="accountName" label="账户名" width="246"></el-table-column>
      <el-table-column prop="bankName" label="开户行" width="246"></el-table-column>
      <el-table-column prop="bankNum" label="银行账号" width="246"></el-table-column>
      <el-table-column prop="cashierUser" label="出纳人员" width="120"></el-table-column>
      <el-table-column prop="accountingUser" label="会计人员" width="120">
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <div class="btn_view">
            <el-button type="text" size="small" @click="onBtn(1,scope.row.id)">编辑</el-button>
            <el-button type="text" size="small" @click="onBtn(2,scope.row.id)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="add_info_popup" v-if="addPopup.show">
      <div class="mask"></div>
      <div class="bad">
        <div class="top_view">
          <div class="title">编辑企业信息</div>
          <i class="el-icon-close" @click="addPopup.show = false"></i>
        </div>
        <div class="content_view">
          <!-- <div class="content_top_view">
            <div class="title_view">
              <div class="link"></div>
              <div class="text">收款人信息</div>
            </div>
            <div class="contnen_info_view">
              <div class="info_view">
                <div class="title">企业名称:</div>
                <div class="info">{{username}}</div>
              </div>
              <div class="info_view">
                <div class="title">账户姓名:</div>
                <div class="info">某某某</div>
              </div>
              <div class="info_view">
                <div class="title kuan">身份证号码:</div>
                <div class="info">215456********5431</div>
              </div>
              <div class="info_view">
                <div class="title">联系方式:</div>
                <div class="info">152****5454</div>
              </div>
            </div>
          </div> -->

          <div class="content_top_view">
            <div class="title_view">
              <div class="link"></div>
              <div class="text">收款信息</div>
            </div>
            <div class="contnen_info_view">
              <div class="info_view">
                <div class="must">*</div>
                <div class="title">账户名:</div>
                <div class="input">
                  <el-input v-model="addPopup.data.accountName"></el-input>
                </div>
              </div>
              <div class="info_view">
                <div class="must">*</div>
                <div class="title">开户行:</div>
                <div class="input">
                  <el-input v-model="addPopup.data.bankName"></el-input>
                </div>
              </div>
              <div class="info_view">
                <div class="must">*</div>
                <div class="title">银行账号:</div>
                <div class="input">
                  <el-input v-model="addPopup.data.bankNum"></el-input>
                </div>
              </div>
              <div class="info_view">
                <div class="must">*</div>
                <div class="title">出纳人员:</div>
                <div class="input">
                  <el-input v-model="addPopup.data.cashierUser"></el-input>
                </div>
              </div>
              <div class="info_view">
                <div class="must">*</div>
                <div class="title">会记人员:</div>
                <div class="input">
                  <el-input v-model="addPopup.data.accountingUser"></el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="btn_view">
            <div class="btn cancel" @click="addPopup.show = false">取消</div>
            <div class="btn confirm" @click="onAdd">添加</div>
          </div>
        </div>
      </div>
    </div>


    <div class="popup_view" v-if="popupInfoThis.show">
      <div class="mask"></div>
      <div class="content_view">
        <div class="title">安全验证</div>
        <div class="content_text">为了账号安全，需要向手机号 158****8888
          发送安全验证码，通过手机安全验证才能进行下一步操作
        </div>
        <div class="input_view">
          <input class="hide-input" @input="upInput" maxlength="6" type="text"
                 v-model="popupInfoThis.data.code"
                 onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"/>
          <div class="input" v-for="(v,k) in popupInfoThis.data.codeArr" :key="k">{{ v }}</div>
        </div>

        <div class="btn_user_view">
          <div class="btn cancel" @click="onCancelVer">关闭</div>
          <div class="btn confirm" @click="onReacquire">
            重新获取{{
              popupInfoThis.data.verificationCodeTime > 0 ? `（${popupInfoThis.data.verificationCodeTime}s）` : ''
            }}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "user_info_list",
  props: {
    getClauseList: Function,
    goToView: Function,
    companyInfo: Object,
    config: {
      type: Object,
      default: () => {
        return {
          isTag: true,
          type: "index"
        }
      },
    }, popupInfo: Object, upPopup: Object
  },
  data: () => {
    return {
      popupInfoThis: {
        show: false,
        data: {
          codeArr: ["", "", "", "", "", ""],
          code: "",
          verificationCodeTime: 60
        }
      },
      username: window.sessionStorage.getItem("username"),
      tycode: window.sessionStorage.getItem("tycode"),
      addPopup: {
        show: false,
        data: {
          accountName: '', bankNum: '', bankName: '', bankAccount: '', cashierUser: '', accountingUser: ''
        }
      },
      tableAccountData: []
    }
  },
  mounted() {
    this.getCompanyAccountInfo(this.companyInfo.id);
  },
  methods: {
    getCompanyAccountInfo(companyInfoId) {  
      this.$http({
        url: this.$http.adornUrl("/app/companyapplyaccount/infoList"),
        method: "post",
        data: this.$http.adornData({
          companyInfoId: companyInfoId
        }),
      }).then((data) => {
        if (data && data.code === 0) {
          this.tableAccountData = data.page;
        } else {
          this.tableAccountData = [];
        }
      });
    },


    onAdd() {
      this.$http({
        url: this.$http.adornUrl(`/app/companyapplyaccount/save`),
        method: 'post',
        data: this.$http.adornData({
          'companyInfoId': this.companyInfo.id,
          'accountName': this.addPopup.data.accountingUser,
          'bankNum': this.addPopup.data.bankNum,
          'bankName': this.addPopup.data.bankName,
          'accountingUser': this.addPopup.data.accountingUser,
          'bankAccount': this.addPopup.data.bankAccount,
          'cashierUser': this.addPopup.data.cashierUser,
        })
      }).then((data) => {
        if (data && data.code == 0) {
          this.$message.success("添加成功");
          this.addPopup.show = false;
          this.getCompanyAccountInfo(this.companyInfo.id);
          for (const k in this.addPopup.data) this.addPopup.data[k] = '';
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    getCode() {
      this.popupInfoThis.data.verificationCodeTime = 60;
      localStorage.setItem('verificationCodeTime', "60");
      const _t = this;
      const times = setInterval(() => {
        const time = (localStorage.getItem('verificationCodeTime') - 1);
        _t.popupInfoThis.data.verificationCodeTime = time;
        if (time >= 0) localStorage.setItem('verificationCodeTime', time.toString());
        else clearInterval(times);
      }, 1000)
    },
    onReacquire() {
      if (this.popupInfoThis.data.verificationCodeTime < 0) this.getCode();
      else this.$message.error(`请${this.popupInfoThis.data.verificationCodeTime}秒后重试.`)
    },
    onCancelVer() {
      this.popupInfoThis.show = false;
      for (let k in this.popupInfoThis.data.codeArr) this.popupInfoThis.data.codeArr[k] = "";
      this.popupInfoThis.data.code = "";
    },
    upInput(e) {
      if (e.inputType === "insertText") {
        if (e.data.replace(/[^\d.]/g, '')) {
          for (let k in this.popupInfoThis.data.codeArr) {
            const v = this.popupInfoThis.data.codeArr[k];
            if (v == "") {
              let codeArr = Object.assign({}, this.popupInfoThis.data.codeArr);
              codeArr[k] = e.data;
              this.popupInfoThis.data.codeArr = codeArr;
              break;
            }
          }
        }
      } else if (e.inputType === "deleteByCut") {
        let codeArr = Object.assign({}, this.popupInfoThis.data.codeArr);
        for (let k in codeArr) codeArr[k] = "";
        this.popupInfoThis.data.codeArr = codeArr;
      } else if (e.inputType === "deleteContentBackward") {
        let codeArr = Object.assign({}, this.popupInfoThis.data.codeArr);
        if (this.popupInfoThis.data.code === "") for (let k in codeArr) codeArr[k] = "";
        else {
          let delArr = [];
          for (let k in codeArr) {
            const v = codeArr[k];
            if (v !== "") delArr.push(k);
          }
          codeArr[delArr[delArr.length - 1]] = "";
        }
        this.popupInfoThis.data.codeArr = codeArr;
      } else if (e.inputType === "insertFromPaste") {
        let codeArr = Object.assign({}, this.popupInfoThis.data.codeArr);
        const codeStrArr = this.popupInfoThis.data.code.split("");
        let i = 0;
        for (let k in codeStrArr) {
          const v = codeStrArr[k];
          if (v.replace(/[^\d.]/g, '')) {
            codeArr[i] = v;
            i++;
          }
        }
        this.popupInfoThis.data.codeArr = codeArr;
      } else {
        let codeArr = Object.assign({}, this.popupInfoThis.data.codeArr);
        for (let k in codeArr) codeArr[k] = "";
        this.popupInfoThis.data.code = "";
      }
    },
    onBtn(type, id) {
      if (type === 1) {
        this.popupInfo.data.title = "安全提醒";
        this.popupInfo.data.desc = "检测到您的闽政通个人手机号为完善，请前往闽政通完善个人信息后再进行安全验证";
        this.popupInfo.data.cancel = "关闭";
        this.popupInfo.data.confirm = "";
      } else if (type === 2) {
        // this.popupInfo.data.title = "安全验证";
        // this.popupInfo.data.desc = "为了账号安全，需要向手机号  158****8888  发送安全验证码，通过手机安全验证才能进行下一步操作";
        // this.popupInfo.data.cancel = "关闭";
        // this.popupInfo.data.confirm = "获取验证码";
        // this.popupInfo.onConfirm = () => {
        //   this.getCode();
        //   this.popupInfo.show = false;
        //   this.popupInfoThis.show = true;
        // };
        this.$http({
          url: this.$http.adornUrl(`/app/companyapplyaccount/delete`),
          method: 'post',
          data: this.$http.adornData({
            'accountId': id,
          })
        }).then((data) => {
          if (data && data.code == 0) {
            this.$message.success("删除成功");
            this.getCompanyAccountInfo(this.companyInfo.id);
          } else {
            this.$message.error(data.msg)
          }
        })

      }

      // this.popupInfo.show = true;
      // this.popupInfo.data.btnType = 2;
      // this.popupInfo.data.contentType = 2;

      //console.log(type, id)
    },
    bankCardTran(str) {
      const reg = /^(\d{4})\d+(\d{4})$/;
      str = str.replace(reg, "$1 **** **** $2");
      return str;
    },

    // 每页数
    sizeChangeHandle(val) {
      this.clause.pageSize = val;
      this.clause.pageIndex = 1;
      this.getClauseList(this.clause.pageIndex);
    },
    // 当前页
    currentChangeHandle(val) {
      console.log("test" + val);
      this.clause.pageIndex = val;
      this.getClauseList(this.clause.pageIndex);
    },
  }
}
</script>

<style lang="scss" scoped>
.add_info_popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;

  .bad {
    width: 1000px;
    background: #FFFFFF;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;

    .content_view {

      .btn_view {
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        justify-content: center;

        .btn {
          width: 200px;
          height: 44px;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 16px;
          font-family: Microsoft YaHei, serif;
          font-weight: 400;
          line-height: 24px;
          cursor: pointer;
          user-select: none;

          &:hover {
            opacity: 0.5;
          }

          &.cancel {
            background: #FFFFFF;
            border: 1px solid #DDDDDD;
            color: #999999;
          }

          &.confirm {
            background: #1156A3;
            color: #FFFFFF;
            margin-left: 20px;
          }
        }
      }

      .content_top_view {
        margin-left: 30px;
        margin-right: 30px;

        .contnen_info_view {
          width: 940px;
          background: #F5F5F5;
          margin-top: 16px;
          margin-bottom: 30px;
          padding: 24px 24px 0;

          .info_view {
            display: flex;
            align-items: center;

            font-size: 14px;
            font-family: Microsoft YaHei, serif;
            font-weight: 400;
            color: #333333;
            line-height: 27px;
            padding-bottom: 10px;

            .input {
              margin-left: 35px;
              height: 40px;
              width: 600px;
              background: #FFFFFF;
              border: 1px solid #DDDDDD;

              display: flex;
              align-items: center;
              justify-content: center;

              ::v-deep .el-input__inner {
                border: 0;
              }
            }

            .must {
              font-size: 14px;
              font-family: Microsoft YaHei, serif;
              font-weight: 400;
              color: #F70505;
              line-height: 24px;
              margin-right: 8px;
            }

            .title {
              width: 71px;
              font-size: 14px;
              font-family: Microsoft YaHei, serif;
              font-weight: 400;
              color: #333333;
              line-height: 24px;

              &.kuan {
                width: 85px;
              }
            }

            &:last-child {
              padding-bottom: 24px;
            }
          }
        }

        .title_view {
          display: flex;
          align-items: center;

          .text {
            font-size: 16px;
            font-family: Microsoft YaHei, serif;
            font-weight: 400;
            color: #333333;
            line-height: 24px;

            margin-left: 9px;
          }

          .link {
            width: 3px;
            height: 18px;
            background: #1156A3;
          }
        }
      }

    }

    .top_view {
      width: 100%;
      display: flex;

      .el-icon-close {
        margin-top: 19px;
        margin-right: 24px;

        font-size: 16px;
        color: #666666;
      }

      .title {
        margin-top: 18px;
        margin-left: 24px;
        margin-bottom: 18px;

        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
        flex: 1;
      }
    }
  }

  .mask {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

}

.popup_view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;

  .content_view {
    width: 720px;
    background: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;

    .btn_user_view {
      width: 100%;
      margin-top: 35px;
      display: flex;

      .btn {
        flex: 1;
        height: 72px;
        background: #FFFFFF;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 20px;
        font-family: Microsoft YaHei, serif;
        font-weight: 400;
        line-height: 24px;

        border: 1px solid #DDDDDD;

        &.cancel {
          color: #888888;
        }

        &.confirm {
          color: #1156A3;
        }
      }
    }

    .btn_view {
      margin-top: 35px;
      margin-bottom: 30px;

      display: flex;

      .btn {
        width: 200px;
        height: 44px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 16px;
        font-family: Microsoft YaHei, serif;
        font-weight: 400;
        line-height: 24px;

        &.cancel {
          background: #FFFFFF;
          border: 1px solid #DDDDDD;
          color: #999999;
        }

        &.confirm {
          background: #1156A3;
          color: #FFFFFF;
          margin-left: 20px;
        }
      }
    }

    .content_html {
      width: 595px;
      font-size: 18px;
      font-family: Microsoft YaHei, serif;
      font-weight: 400;
      color: #333333;
      line-height: 27px;

      margin-top: 29px;
    }

    .input_view {
      display: flex;
      position: relative;
      margin-top: 24px;

      .hide-input {
        position: absolute;
        height: 70px;
        width: 100%;
        color: transparent;
        background: transparent;
        opacity: 0;
        z-index: 10;
        border: none;
        outline: none;
      }

      .input {
        width: 70px;
        height: 70px;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;

        margin-left: 12px;

        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 24px;
      }
    }

    .content_text {
      width: 595px;
      font-size: 18px;
      font-family: Microsoft YaHei, serif;
      font-weight: 400;
      color: #666666;
      line-height: 30px;

      margin-top: 29px;
    }

    .title {
      font-size: 24px;
      font-family: Microsoft YaHei, serif;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
      margin-top: 40px;
    }
  }

  .mask {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}

.dropdown {
  width: 400px;

  ::v-deep .popper__arrow {
    left: 10px !important;
  }
}

.search_view {
  display: flex;
  margin-top: 9px;
  margin-bottom: 24px;

  .btn_view {
    width: 144px;
    height: 40px;
    background: #1156A3;

    font-size: 16px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
    margin-left: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 0.5;
    }

    &:nth-child(2) {
      background: #FFFFFF;
      border: 1px solid #1156A3;
      color: #1156A3;
    }
  }
}

.btn_view {
  display: flex;
  align-items: center;
}

.pagination {
  padding-bottom: 20px;
  padding-right: 20px;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;

  &.green:before {
    background: #12E096;
  }

  &.blue:before {
    background: #3988FF;
  }

  &.yellow:before {
    background: #FFA70E;
  }

  &:before {
    content: "";
    margin-right: 8px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}


.endTime {
  text-align: center;
}

.red {
  color: #E40000;
}
</style>