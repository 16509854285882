<template>
  <div class="popup_view" v-if="popupInfo.show">
    <div class="mask"></div>
    <div class="content_view">
      <div class="title">选择账号</div>
      <div class="content">
        <div v-for="(v,k) in list" :key="k" :class="{item:true,check:v.isCheck}" @click="onCheck(k)">
          <div class="number">{{ v.bankNum }}</div>
          <div class="info_view">
            <div class="name">账户名：{{ v.accountName }}</div>
            <div class="that">开户行：{{ v.bankName }}</div>
          </div>
          <div :class="{check_view:true,check:v.isCheck}">
            <i class="el-icon-check" v-if="v.isCheck"></i>
          </div>
        </div>
        <div class="btn_view">
          <div class="btn cancel" @click.stop="popupInfo.show = false">取消</div>
          <div class="btn confirm" @click.stop="onDetermine">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companyInfo: Object,
    popupInfo: {
      type: Object,
      default() {
        return {
          show: false
        }
      }
    }
  },
  data() {
    return {
      list: []
    }
  },
  async mounted() {
    const infoListRec = await this.$http.sPost("/app/companyapplyaccount/infoList", {
      companyInfoId: this.companyInfo.id
    });
    if (infoListRec.code === 0) {
      infoListRec.page.forEach(v => {
        this.list.push({
          ...v, ...{
            isCheck: false
          }
        });
      })
    }
  },
  methods: {
    onDetermine() {
      this.popupInfo.onDetermine(this.list.find(o => o.isCheck === true));
      this.popupInfo.show = false;
    },
    onCheck(k) {
      this.list.forEach((value, key) => {
        if (key === k) value.isCheck = true;
        else value.isCheck = false;
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.popup_view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;

  .content_view {
    width: 720px;
    background: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;

    .btn_view {
      margin-top: 35px;

      display: flex;

      .btn {
        flex: 1;
        height: 72px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 20px;
        font-family: Microsoft YaHei, serif;
        font-weight: 400;
        line-height: 24px;

        &.cancel {
          background: #FFFFFF;
          border: 1px solid #DDDDDD;
          color: #999999;
        }

        &.confirm {
          border: 1px solid #DDDDDD;
          color: #1156A3;
        }
      }
    }


    .content {
      width: 100%;
      margin-top: 29px;

      .item {
        width: 600px;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        margin: 16px auto;
        color: #888888;
        position: relative;

        .check_view {
          position: absolute;
          right: 24px;
          top: 50%;
          transform: translateY(-50%);

          width: 24px;
          height: 24px;
          background: #FFFFFF;
          border: 1px solid #BBBBBB;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          .el-icon-check {
            font-weight: bold;
          }

          &.check {
            background: rgba(2, 136, 223, 0.2);
            color: #0288DF;
            border: none;
          }
        }

        .info_view {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 24px;
          display: flex;
          margin-left: 24px;
          margin-top: 15px;
          margin-bottom: 25px;

          .that {
            margin-left: 63px;
          }
        }

        .number {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 24px;

          margin-top: 23px;
          margin-left: 24px;
        }


        &.check {
          border: 1px solid #0288DF;
          color: #115697;
        }

        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .title {
      font-size: 24px;
      font-family: Microsoft YaHei, serif;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
      margin-top: 40px;
    }
  }

  .mask {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>
