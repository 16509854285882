<template>
  <div class="content">
    <div class="search_view">
      <div class="search_input">
        <el-input placeholder="请输入条款标题" v-model="clause.clauseName"></el-input>
      </div>
      <!-- <div class="search_dropdown">
        <el-dropdown placement="bottom-start" trigger="hover">
          <div class="dropdown_text el-dropdown-link">
            <div class="text">请选择申报状态</div>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown" class="dropdown"  >
            <el-dropdown-item >待审核</el-dropdown-item>
            <el-dropdown-item >已通过</el-dropdown-item>
            <el-dropdown-item >已驳回</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div> -->
      <div class="btn_view" @click="getCompanyApplyList()">搜索</div>
    </div>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="id" label="序号" width="56"></el-table-column>
      <el-table-column prop="clauseName" label="条款标题" width="146"></el-table-column>
      <el-table-column prop="applyTime" label="申请日期"></el-table-column>
      <el-table-column prop="printedTime" label="印发日期"></el-table-column>
      <el-table-column prop="isValid" label="是否长期有效">
        <template v-slot="scope">

          {{
            `${scope.row.isValid == 1 ? "是" : "否"}`
          }}
        </template>
      </el-table-column>
      <el-table-column prop="validEndTime" label="实施截止日期">
        <template v-slot="scope">
          <p :class="{endTime:true,red:scope.row.isValid === 1 ? false :isLtTime(scope.row.validEndTime)}">
            {{
              `${scope.row.isValid === 1 ? "--" : scope.row.validEndTime ? "--" : scope.row.validEndTime.replace(/-/g, "/")}\n${isLtTime(scope.row.validEndTime) ? "(已过期)" : ""}`
            }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="申报状态">
        <template v-slot="scope">
          <p :class="{status:true,green:scope.row.status == 0,blue:(scope.row.status == 1 || scope.row.status == 2),yellow:scope.row.status == 3 }">
            {{
              `${scope.row.status == 0 ? "待审核" : scope.row.status == 1 ? "已通过" : "已驳回"}`
            }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="answerContent" label="审核备注">
        <template v-slot="scope">
          <p>
            {{
              `${scope.row.answerContent ? scope.row.answerContent : "-"}`
            }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="cashMoney" label="补助金额"></el-table-column>
      <el-table-column prop="isSure" label="是否收到款项">
        <template v-slot="scope">
          <p>
            {{
              `${scope.row.isSure == 0 ? "否" : "是"}`
            }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作">
        <template v-slot="scope">
          <div class="btn_view">
            <el-button type="text" size="small" @click="onBtn(1,scope.row.id,scope.row.cashMoney)">查看详情</el-button>
            <el-button type="text" size="small" @click="onBtn(2,scope.row.id,scope.row.cashMoney)">我要反馈</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="pagination"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="clause.pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="clause.pageSize"
        :total="totalCount"
        layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <div class="declare_info_popup" v-if="declarePopup.show">
      <div class="mask"></div>
      <div class="bad">
        <div class="top_view">
          <div class="title">申报详情</div>
          <i class="el-icon-close" @click="declarePopup.show = false"></i>
        </div>
        <div class="content_view">
          <div class="content_top_view">
            <div class="title_view">
              <div class="link"></div>
              <div class="text">当前进度</div>
            </div>
            <div class="step_list">
              <div
                  :class="{step_item:true, on_click:poType === 2}" @click="poType===2 && (poType = 1)">
                <el-image class="step_icon" :src="require('@/views/index/image/ico-success@2x.png')"></el-image>
                <div class="step_text check">提交信息</div>
              </div>
              <div class="next_step_line check">· · · · · · · · · · · · ·</div>
              <div :class="{step_item:true, on_click:poType === 1}" @click="poType===1 && (poType = 2)">
                <el-image class="step_icon" :src="require('@/views/index/image/ico-wait@2x.png')"></el-image>
                <div class="step_text">上传材料</div>
              </div>
              <div class="next_step_line">· · · · · · · · · · · · ·</div>
              <div class="step_item">
                <div class="step_bs">3</div>
                <div class="step_text">审核</div>
              </div>
              <div class="next_step_line">· · · · · · · · · · · · ·</div>
              <div class="step_item">
                <div class="step_bs">4</div>
                <div class="step_text">完成申报</div>
              </div>
            </div>
          </div>

          <div class="content_top_view" v-if="poType === 1">
            <div class="title_view">
              <div class="link"></div>
              <div class="text">提交信息</div>
            </div>
            <div class="contnen_info_view" v-if="poType === 1">
              <div class="form_item" v-for="(v,k) in formList" :key="k">
                <div class="name_view">
                  <div class="tip" v-if="v.isMust">*</div>
                  <div class="name">{{ v.title }}</div>
                </div>
                <div class="input">
                  <el-input :placeholder="v.placeholder" v-model="v.val"></el-input>
                  <div class="view" v-if="v.name == 'zhm'">
                    <div class="link"></div>
                    <div class="btn" @click="selectAccount">选择账号</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="content_top_view">
            <div class="title_view">
              <div class="link"></div>
              <div class="text">审核备注</div>
            </div>
            <div class="contnen_info_view">
              <div class="log_list">

                <div class="log_item">
                  <div class="top_item">
                    <div class="di"></div>
                    <div class="department">审核时间：</div>
                    <div class="time">{{ companyApply.answerTime }}</div>
                  </div>
                  <div class="desc">{{ companyApply.answerContent }}</div>
                </div>

              </div>
            </div>
          </div>


          <div class="content_top_view" style="margin-top: 24px">
            <div class="title_view">
              <div class="link"></div>
              <div class="text">申报材料</div>
            </div>
            <div class="roll_view">
              <div class="contnen_info_view" v-for="(v,k) in companyApply.fileList" :key="k">
                <div class="info_view">
                  <div class="title">提交材料:</div>
                  <div class="input">
                    <el-input v-model="v.fileName"></el-input>
                    <a class="btn" :href="'/file/company/' + v.fileName" target="_blank">下载材料</a>
                  </div>
                </div>
                <div class="info_view">
                  <div class="title">备注:</div>
                  <div class="input">
                    <el-input v-model="v.remarks"></el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="add_btn_view" v-if="poType === 2">
            <div class="add_btn" @click="onAddContnen">
              <i class="el-icon-plus"></i>
              新增材料
            </div>
          </div> -->
          <div class="btn_view">
            <div class="btn confirm" @click="declarePopup.show = false">关闭</div>
          </div>
        </div>
      </div>
    </div>
    <select_account :popup-info="popupInfos" :company-info="companyInfo"></select_account>
  </div>
</template>

<script>
import TWEEN from '@tweenjs/tween.js';

import select_account from "@/views/index/home/compoents/select_account"

export default {
  name: "user_declare_list",
  components: {select_account},
  props: {
    goToView: Function,
    companyInfo: Object,
    config: {
      type: Object,
      default: () => {
        return {
          isTag: true,
          type: "index"
        }
      },
    }, popupInfo: Object,
  },
  data: () => {
    return {
      clause: {
        codeName: null,
        page: 1,
        clauseName: "",
        status: "",
        limit: 10
      },
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      tableData: [],
      popupInfos: {
        show: false,
        onDetermine: null,
      },
      companyApply: {
        answerContent: "",
        answerTime: "",
        deptName: "",
        status: "",
        cashMoney: "",
        fileList: {},
      },
      formList: [],
      poType: 2,
      tycode: window.sessionStorage.getItem("tycode"),
      appMateList: [
        {
          submittals: "",
          remarks: ""
        }
      ],
      declarePopup: {
        show: false
      }
    }
  },
  mounted() {
    requestAnimationFrame(this.animate)
    this.getCompanyApplyList(1);
    this.getCompanyAccount(this.companyInfo.id);
  },
  methods: {
    selectAccount() {
      this.popupInfos.onDetermine = (val) => {
        this.formList[0].val = val.name;
        this.formList[1].val = val.that;
        this.formList[2].val = val.number;
      }
      this.popupInfos.show = true;
    },
    getCompanyAccount() {
      this.$http({
        url: this.$http.adornUrl('/app/companyapplyaccount/infoList'),
        method: 'post',
        data: this.$http.adornData({
          "companyInfoId": this.companyInfo.id
        })
      }).then((data) => {
        if (data && data.code === 0) {
          this.formList = data.formList

        }
      })
    },

    onAddContnen() {
      this.appMateList.push({submittals: "", remarks: ""});
      this.scrollToBottom(".roll_view");
    },
    onBtn(type, id, cashMoney) {
      this.$http({
        url: this.$http.adornUrl("/app/companyapply/getInfo"),
        method: "post",
        data: this.$http.adornData({
          id: id
        }),
      }).then((data) => {
        if (data && data.code === 0) {
          this.companyApply = data.companyApply;
        } else {
          this.companyApply = {};
        }
      });

      if (type === 1) {
        this.appMateList = [{submittals: "", remarks: ""}];
        this.declarePopup.show = true;
      } else if (type === 2) {
        this.popupInfo.show = true;
        this.popupInfo.data.title = "申报反馈";
        this.popupInfo.data.desc = "企业是否已收到财政部拨付的补助金额￥" + cashMoney + "元，如已收到请点击”确认收到“。";
        this.popupInfo.data.cancel = "没有收到款项";
        this.popupInfo.data.confirm = "确认收到款项";
        this.popupInfo.data.btnType = 2;
        this.popupInfo.data.contentType = 2;
        this.popupInfo.onConfirm = () => {
          this.popupInfo.show = false;
          this.$http({
            url: this.$http.adornUrl('/app/companyapply/isSure'),
            method: 'post',
            data: this.$http.adornData({
              'id': id,
            })
          }).then(async (data) => {
            if (data && data.code === 0) {
              this.$message.success("确定成功");
              this.getCompanyApplyList();
            } else {
              this.$message.error(data.msg)
            }
          })
        };

        this.popupInfo.onCancel = () => {
          this.popupInfo.show = false;
        };

      }
    },
    isLtTime(time) {
      if (time) {
        time = new Date(time.replace(/-/g, "/")).getTime();
        const thisTime = new Date().getTime();
        if (thisTime > time) return true;
        else return false;
      } else return false;

    },
    animate(time) {
      requestAnimationFrame(this.animate)
      TWEEN.update(time)
    },
    scrollToBottom(selectors) {
      this.$nextTick(() => {
        const container = this.$el.querySelector(selectors);
        const coords = {y: container.scrollTop}
        new TWEEN.Tween(coords)
            .to({y: container.scrollHeight},
                container.scrollHeight - container.scrollTop < 3000 ? container.scrollHeight - container.scrollTop : 3000)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onUpdate(() => container.scrollTop = coords.y).start();
      });
    },
    getCompanyApplyList(page) {
      if (page) this.clause.page = page
      this.clause.limit = this.pageSize
      this.clause.codeName = this.tycode
      this.$http({
        url: this.$http.adornUrl('/app/companyapply/list'),
        method: 'post',
        data: this.$http.adornData(this.clause)
      }).then((data) => {
        if (data && data.code === 0) {
          this.tableData = data.page.list
          this.totalCount = data.page.totalCount
        } else {
          this.tableData = []
          this.totalCount = 0
        }
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.clause.pageSize = val;
      this.clause.pageIndex = 1;
      this.getCompanyApplyList(this.clause.pageIndex);
    },
    // 当前页
    currentChangeHandle(val) {
      this.clause.pageIndex = val;
      this.getCompanyApplyList(this.clause.pageIndex);
    },
  }
}
</script>

<style lang="scss" scoped>

.declare_info_popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;


  .bad {
    width: 1000px;
    background: #FFFFFF;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;

    .content_view {

      .btn_view {
        margin-top: 40px;
        margin-bottom: 40px;
        display: flex;
        justify-content: center;

        .btn {
          width: 200px;
          height: 44px;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 16px;
          font-family: Microsoft YaHei, serif;
          font-weight: 400;
          line-height: 24px;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          &.cancel {
            background: #FFFFFF;
            border: 1px solid #DDDDDD;
            color: #999999;
          }

          &.confirm {
            background: #1156A3;
            color: #FFFFFF;
            margin-left: 20px;
          }
        }
      }

      .add_btn_view {
        margin-left: 30px;

        .add_btn {
          width: 160px;
          height: 40px;
          background: #FFFFFF;
          border: 1px solid #1156A3;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1156A3;
          line-height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          user-select: none;

          &:hover {
            opacity: 0.8;
          }

          i {
            width: 14px;
            height: 14px;
            color: #1156A3;
            margin-right: 10px;
          }
        }
      }

      .content_top_view {
        margin-left: 30px;
        margin-right: 30px;

        .form_item {
          display: flex;
          align-items: center;
          padding-bottom: 24px;

          .name_view {
            display: flex;
            align-items: center;
            width: 71px;
          }

          .input {
            margin-left: 45px;
            width: 600px;
            border: 1px solid #DCDFE6;
            border-radius: 4px;
            display: flex;
            align-items: center;

            ::v-deep .el-input__inner {
              border: none;
            }

            .view {
              display: flex;
              right: 0;
              top: 0;

              white-space: nowrap;

              .link {
                margin-left: 10px;
                margin-right: 20px;
                width: 1px;
                height: 20px;
                background: #DDDDDD;
              }

              .btn {
                margin-right: 20px;
                font-size: 14px;
                font-family: Microsoft YaHei, serif;
                font-weight: 400;
                color: #1156A3;
                line-height: 24px;
              }
            }
          }

          .name {
            font-size: 14px;
            font-family: Microsoft YaHei, serif;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }

          .tip {
            font-size: 14px;
            font-family: Microsoft YaHei, serif;
            font-weight: 400;
            color: #F70505;
            margin-right: 3px;
          }
        }

        .roll_view {
          max-height: 144px * 1.5;
          overflow: hidden;
          overflow-y: scroll;
          -ms-overflow-style: none;
          margin-top: 16px;
          margin-bottom: 30px;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: none;
            border-radius: 360px;
            background: rgba(197, 193, 193, 0.6);
          }

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
            border-radius: 360px;
            background: rgba(197, 193, 193, 0.3);
          }

        }


        .step_list {
          display: flex;
          width: 100%;
          background: #FFFFFF;
          align-items: center;
          justify-content: center;

          margin-top: 50px;
          margin-bottom: 50px;

          .next_step_line {
            width: 180px;
            color: #DDDDDD;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-bottom: 32px;
            user-select: none;

            &.check {
              color: #1156A3;
            }
          }

          .step_item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            user-select: none;

            &.on_click {

              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }
            }

            .step_bs {
              width: 48px;
              height: 48px;
              background: #FFFFFF;
              border: 1px solid #DDDDDD;
              border-radius: 50%;

              display: flex;
              justify-content: center;
              align-items: center;

              font-size: 20px;
              font-family: Microsoft YaHei, serif;
              font-weight: 400;
              color: #AAAAAA;
              line-height: 24px;
            }

            .step_icon {
              width: 48px;
              height: 48px;
            }

            .step_text {
              font-size: 14px;
              font-family: Microsoft YaHei, serif;
              font-weight: 400;
              line-height: 24px;
              color: #333333;
              margin-top: 16px;

              &.check {
                color: #1156A3;
              }
            }
          }
        }

        .contnen_info_view {
          width: 940px;
          background: #F5F5F5;
          margin-top: 30px;
          //margin-bottom: 30px;
          padding: 24px 24px 0;

          &:nth-child(1) {
            margin-top: 0;
          }

          .log_list {
            padding-bottom: 23px;

            .log_item {
              .desc {
                margin-left: 16px;
                font-size: 16px;
                font-family: Microsoft YaHei, serif;
                font-weight: 400;
                color: #333333;
                line-height: 27px;
              }
            }

            .top_item {
              display: flex;
              align-items: center;

              .time {
                font-size: 14px;
                font-family: Microsoft YaHei, serif;
                font-weight: 400;
                color: #888888;
                line-height: 27px;
              }

              .department {
                font-size: 14px;
                font-family: Microsoft YaHei, serif;
                font-weight: 400;
                color: #666666;
                line-height: 27px;
                margin-right: 18px;
              }

              .di {
                width: 8px;
                height: 8px;
                background: #4178B5;
                border-radius: 50%;
                margin-right: 8px;
              }
            }
          }

          .info_view {
            display: flex;
            align-items: center;

            font-size: 14px;
            font-family: Microsoft YaHei, serif;
            font-weight: 400;
            color: #333333;
            line-height: 27px;
            padding-bottom: 10px;

            .input {
              margin-left: 35px;
              height: 40px;
              width: 600px;
              background: #FFFFFF;
              border: 1px solid #DDDDDD;

              display: flex;
              align-items: center;
              justify-content: center;

              .btn {
                font-size: 14px;
                font-family: Microsoft YaHei, serif;
                font-weight: 400;
                color: #1156A3;
                line-height: 24px;
                display: flex;
                align-items: center;
                margin-right: 20px;
                white-space: nowrap;

                &::before {
                  content: "";
                  display: block;
                  width: 1px;
                  height: 20px;
                  background: #DDDDDD;
                  margin-right: 20px;
                }
              }

              ::v-deep .el-input__inner {
                border: 0;
              }
            }

            .must {
              font-size: 14px;
              font-family: Microsoft YaHei, serif;
              font-weight: 400;
              color: #F70505;
              line-height: 24px;
              margin-right: 8px;
            }

            .title {
              width: 71px;
              font-size: 14px;
              font-family: Microsoft YaHei, serif;
              font-weight: 400;
              color: #333333;
              line-height: 24px;

              &.kuan {
                width: 85px;
              }
            }

            &:last-child {
              padding-bottom: 24px;
            }
          }
        }

        .title_view {
          display: flex;
          align-items: center;

          .text {
            font-size: 16px;
            font-family: Microsoft YaHei, serif;
            font-weight: 400;
            color: #333333;
            line-height: 24px;

            margin-left: 9px;
          }

          .link {
            width: 3px;
            height: 18px;
            background: #1156A3;
          }
        }
      }

    }

    .top_view {
      width: 100%;
      display: flex;

      .el-icon-close {
        margin-top: 19px;
        margin-right: 24px;

        font-size: 16px;
        color: #666666;
      }

      .title {
        margin-top: 18px;
        margin-left: 24px;
        margin-bottom: 18px;

        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
        flex: 1;
      }
    }
  }

  .mask {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

}

.dropdown {
  width: 400px;

  ::v-deep .popper__arrow {
    left: 10px !important;
  }
}

.search_view {
  display: flex;
  margin-top: 9px;
  margin-bottom: 24px;

  .btn_view {
    width: 80px;
    height: 40px;
    background: #1156A3;

    font-size: 16px;
    font-family: Microsoft YaHei, serif;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
    margin-left: 10px;

    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 0.5;
    }
  }

  .search_dropdown {
    width: 400px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    margin-left: 10px;

    .el-dropdown {
      width: 100%;
      height: 100%;
      position: relative;


      .dropdown_text {
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-family: Microsoft YaHei, serif;
        font-weight: 400;
        color: #AAAAAA;

        display: flex;
        align-items: center;

        .el-icon--right {
          flex: 0.1;
        }

        .text {
          margin-left: 14px;
          flex: 1.4;
        }
      }
    }
  }

  .search_input {
    width: 400px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;

    align-items: center;
    justify-content: center;
    display: flex;

    ::v-deep .el-input__inner {
      border: 0;
    }
  }
}

::v-deep .el-table th.el-table__cell > .cell {
  text-align: center;
}

::v-deep .el-table td.el-table__cell div {
  text-align: center;
}

.btn_view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ::v-deep .el-button {
    margin-left: 0;
  }
}

.pagination {
  padding-bottom: 20px;
  padding-right: 20px;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;

  &.green:before {
    background: #12E096;
  }

  &.blue:before {
    background: #3988FF;
  }

  &.yellow:before {
    background: #FFA70E;
  }

  &:before {
    content: "";
    margin-right: 8px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}


.endTime {
  text-align: center;
}

.red {
  color: #E40000;
}
</style>
