<template>
  <div class="main_content">
    <top_view></top_view>
    <div class="main">
      <div class="info main">
        <div class="info-name">{{ username }}</div>
        <div class="info-credit">统一社会信用代码：{{ tycode }}</div>
        <div class="btn_view" @click="upPopup.show = true">修改企业信息</div>
        <div class="info-detail">
          <el-descriptions class="info-left-info">
            <el-descriptions-item label="法定代表人">{{ companyInfo.legalpersonname }}</el-descriptions-item>
            <el-descriptions-item label="单位联系方式">{{ companyInfo.phone }}</el-descriptions-item>
            <el-descriptions-item label="单位地址">{{ companyInfo.address }}</el-descriptions-item>
            <el-descriptions-item label="经办人姓名">{{ companyInfo.truename }}</el-descriptions-item>
            <!-- <el-descriptions-item label="经办人邮箱">{{ viewData.companyInfo.email }}</el-descriptions-item> -->
          </el-descriptions>
        </div>
      </div>
      <div class="filter-box" style="background-color: #fff;flex: 1;">
        <div class="top_title">
          <div class="left_line"></div>
          <div class="title_text">待办事项</div>
          <div class="bottom_line"></div>
        </div>
        <div class="top_content_list">
          <div class="top_content_item" v-for="(v,k) in dataSummary" :key="k">
            <div class="icon">
              <el-image :src="v.icon"></el-image>
            </div>
            <div class="re_view">
              <div class="num">
                <div class="text" :style="{color:v.color}">{{ v.num }}</div>
                <div class="company">{{ v.company }}</div>
              </div>
              <div class="title_name">{{ v.title }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="my_list_view" v-if="isLoad">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="推送给我的条款" name="push">
            <policy_list :config="listConfig" :popupInfo="popupInfo" :get-clause-list="getClauseList"></policy_list>
          </el-tab-pane>
          <el-tab-pane label="我申报的条款" name="declare">
            <user_declare_list :clause-list="displayList" :config="listConfig" :company-info="companyInfo"
                               :popupInfo="popupInfo"></user_declare_list>
          </el-tab-pane>
          <el-tab-pane label="智能匹配的条款" name="matching">
            <policy_list :config="listConfig" :popupInfo="popupInfo"
                         :get-clause-list="getMatchingClauseList"></policy_list>
          </el-tab-pane>
          <el-tab-pane label="企业信息管理" name="information">
            <user_info_list :config="listConfig" :popupInfo="popupInfo" :up-popup="upPopup"
                            :company-info="companyInfo"></user_info_list>
          </el-tab-pane>
        </el-tabs>
      </div>


      <div class="add_info_popup" v-if="upPopup.show">
        <div class="mask"></div>
        <div class="bad">
          <div class="top_view">
            <div class="title">编辑企业信息</div>
            <i class="el-icon-close" @click="upPopup.show = false"></i>
          </div>
          <div class="content_view">
            <div class="content_top_view">
              <div class="title_view">
                <div class="link"></div>
                <div class="text">企业信息</div>
              </div>
              <div class="contnen_info_view">
                <div class="info_view">
                  <div class="must">*</div>
                  <div class="title">企业名称：</div>
                  <div class="input">
                    <el-input :value="username" disabled></el-input>
                  </div>
                </div>
                <div class="info_view">
                  <div class="must">*</div>
                  <div class="title">企业法人：</div>
                  <div class="input">
                    <el-input :value="companyInfo.legalpersonname" disabled></el-input>
                  </div>
                </div>
                <div class="info_view">
                  <div class="must">*</div>
                  <div class="title">统一社会编码：</div>
                  <div class="input">
                    <el-input :value="tycode" disabled></el-input>
                  </div>
                </div>
                <div class="info_view">
                  <div class="must">*</div>
                  <div class="title">联系人：</div>
                  <div class="input">
                    <el-input v-model="companyInfo.truename"></el-input>
                  </div>
                </div>
                <div class="info_view">
                  <div class="must">*</div>
                  <div class="title">联系方式：</div>
                  <div class="input">
                    <el-input v-model="companyInfo.phone"></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn_view">
              <div class="btn cancel" @click="upPopup.show = false">取消</div>
              <div class="btn confirm" @click="onUp">修改</div>
            </div>
          </div>
        </div>
      </div>
      <div class="popup_view" v-if="popupInfo.show">
        <div class="mask"></div>
        <div class="content_view">
          <div class="title">{{ popupInfo.data.title }}</div>
          <div class="content_html content" v-if="popupInfo.data.contentType == 1" v-html="popupInfo.data.desc"></div>
          <div class="content_text content" v-if="popupInfo.data.contentType == 2">{{ popupInfo.data.desc }}</div>

          <div :class="{btn_view:popupInfo.data.btnType == 1,btn_user_view:popupInfo.data.btnType == 2}">
            <div class="btn cancel" @click.stop="popupInfo.onCancel" v-if="popupInfo.data.cancel">{{
                popupInfo.data.cancel
              }}
            </div>
            <div class="btn confirm" @click.stop="popupInfo.onConfirm" v-if="popupInfo.data.confirm">{{
                popupInfo.data.confirm
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <suspension_btn/>
  </div>
</template>

<script>
import policy_list from "@/views/index/home/compoents/policy_list";
import user_declare_list from "@/views/index/home/compoents/user_declare_list";
import user_info_list from "@/views/index/home/compoents/user_info_list";
import TWEEN from '@tweenjs/tween.js';

let _t;
export default {
  name: "home",
  components: {user_info_list, policy_list, user_declare_list},
  data: () => {
    return {
      upPopup: {
        show: false,
        data: {enterpriseName: "", enterpriseAddress: '', contacts: '', contactInformation: ''}
      },
      listConfig: {isTag: false, type: "user"},
      dataSummary: [
        {
          icon: require("@/views/index/image/ico-syqy@2x.png"),
          num: 0,
          company: "条",
          title: "已推送条款",
          color: "#3988FF"
        },
        {
          icon: require("@/views/index/image/ico-fbbm@2x.png"),
          num: 0,
          company: "条",
          title: "已确认条款",
          color: "#D92414"
        },
        {
          icon: require("@/views/index/image/ico-tszc@2x.png"),
          num: 0,
          company: "条",
          title: "未确认条款",
          color: "#2EA6A6"
        }
      ],
      activeName: 'push',
      popupInfo: {
        show: false,
        onConfirm: () => _t.popupInfo.show = false,
        onCancel: () => _t.popupInfo.show = false,
        data: {
          id: 0,
          title: "信用承诺书",
          desc: "我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容我是信用承诺书的内容",
          cancel: "取消承诺",
          confirm: "确认承诺",
          btnType: 1,
          contentType: 1
        }
      },
      displayList: {},
      search: {
        page: 1,
        limit: 10,
        companyInfoId: ""
      },
      companyInfo: {},
      username: "",
      tycode: "",
      isLoad: false,
    };
  },
  async mounted() {
    _t = this;
    requestAnimationFrame(this.animate)
    const username = this.username = window.sessionStorage.getItem("username");
    const tycode = this.tycode = window.sessionStorage.getItem("tycode");
    this.companyInfo = (await this.$http.sPost('/app/company/companyinfo', {company: username, tycode})).companyInfo;
    const rec = (await this.$http.sPost("/app/companysend/total", {companyInfoId: this.companyInfo.id}));
    const coords = {pushTotal: 0, sureNum: 0, noPush: 0}
    if (!rec.companySendTotal) {
      rec.companySendTotal = {};
      rec.companySendTotal.pushTotal = 0;
      rec.companySendTotal.sureNum = 0;
    }
    new TWEEN.Tween(coords)
        .to({
              pushTotal: rec.companySendTotal.pushTotal,
              sureNum: rec.companySendTotal.sureNum,
              noPush: rec.companySendTotal.pushTotal - rec.companySendTotal.sureNum
            }, 300
        )
        .easing(TWEEN.Easing.Quadratic.Out)
        .onUpdate(() => {
          this.dataSummary[0].num = Math.trunc(coords.pushTotal);
          this.dataSummary[1].num = Math.trunc(coords.sureNum);
          this.dataSummary[2].num = Math.trunc(coords.pushTotal) - Math.trunc(coords.sureNum);
        }).start();
    this.isLoad = true;
  },
  methods: {
    animate(time) {
      requestAnimationFrame(this.animate)
      TWEEN.update(time)
    },
    onUp() {
      this.$http({
        url: this.$http.adornUrl(`/app/company/updataCompanyinfo`),
        method: 'post',
        data: this.$http.adornData({
          'id': this.companyInfo.id || undefined,
          'truename': this.companyInfo.truename,
          'phone': this.companyInfo.phone,
        })
      }).then((data) => {
        if (data && data.code == 0) {
          this.$message.success("编辑成功");
          this.upPopup.show = false;
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    getMatchingClauseList(page) {
      // return [];
      return new Promise(resolve => {
        this.search.page = page ? page : this.search.page
        this.search.companyInfoId = this.companyInfo.id
        this.$http({
          url: this.$http.adornUrl('/app/huiqiclause/companyClauseList'),
          method: 'post',
          data: this.$http.adornData(this.search)
        }).then((data) => {
          if (data && data.code === 0) {
            for (const k in data.page.list) {
              const v = data.page.list[k];
              const endTime = new Date(v.validEndTime.replace(/-/g, "/")).getTime();
              const thisTime = new Date().getTime();
              data.page.list[k].isEnd = thisTime > endTime;
            }
            resolve(data.page);
          } else resolve(false);
        })
      })
    },
    getClauseList(page) {
      return new Promise(resolve => {
        this.search.page = page ? page : this.search.page
        this.search.companyInfoId = this.companyInfo.id
        this.$http({
          url: this.$http.adornUrl('/app/huiqiclause/companyClauseList'),
          method: 'post',
          data: this.$http.adornData(this.search)
        }).then((data) => {
          if (data && data.code === 0) {
            for (const k in data.page.list) {
              const v = data.page.list[k];
              const endTime = new Date(v.validEndTime.replace(/-/g, "/")).getTime();
              const thisTime = new Date().getTime();
              data.page.list[k].isEnd = thisTime > endTime;
            }
            resolve(data.page);
          } else resolve(false);
        })
      })
    },
    OnConfirm() {
      this.popupInfo.show = false;
    },
    handleClick(tab, event) {
      console.log("标签点击事件", tab, event);
    }
  }
}
</script>

<style lang="scss" scoped>
.main_content {
  background: #f0f4f8;
}

.main {
  max-width: 1300px;
  margin: 0 auto;

  .add_info_popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;

    .bad {
      width: 1000px;
      background: #FFFFFF;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      flex-direction: column;

      .content_view {

        .btn_view {
          margin-top: 40px;
          margin-bottom: 40px;
          display: flex;
          justify-content: center;

          .btn {
            width: 200px;
            height: 44px;

            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 16px;
            font-family: Microsoft YaHei, serif;
            font-weight: 400;
            line-height: 24px;
            cursor: pointer;
            user-select: none;

            &:hover {
              opacity: 0.5;
            }

            &.cancel {
              background: #FFFFFF;
              border: 1px solid #DDDDDD;
              color: #999999;
            }

            &.confirm {
              background: #1156A3;
              color: #FFFFFF;
              margin-left: 20px;
            }
          }
        }

        .content_top_view {
          margin-left: 30px;
          margin-right: 30px;

          .contnen_info_view {
            width: 940px;
            background: #F5F5F5;
            margin-top: 16px;
            margin-bottom: 30px;
            padding: 24px 24px 0;

            .info_view {
              display: flex;
              align-items: center;

              font-size: 14px;
              font-family: Microsoft YaHei, serif;
              font-weight: 400;
              color: #333333;
              line-height: 27px;
              padding-bottom: 10px;

              .input {
                margin-left: 35px;
                height: 40px;
                width: 600px;
                background: #FFFFFF;
                border: 1px solid #DDDDDD;

                display: flex;
                align-items: center;
                justify-content: center;

                ::v-deep .el-input__inner {
                  border: 0;
                }
              }

              .must {
                font-size: 14px;
                font-family: Microsoft YaHei, serif;
                font-weight: 400;
                color: #F70505;
                line-height: 24px;
                margin-right: 8px;
              }

              .title {
                width: 71px;
                font-size: 14px;
                font-family: Microsoft YaHei, serif;
                font-weight: 400;
                color: #333333;
                line-height: 24px;

                &.kuan {
                  width: 85px;
                }
              }

              &:last-child {
                padding-bottom: 24px;
              }
            }
          }

          .title_view {
            display: flex;
            align-items: center;

            .text {
              font-size: 16px;
              font-family: Microsoft YaHei, serif;
              font-weight: 400;
              color: #333333;
              line-height: 24px;

              margin-left: 9px;
            }

            .link {
              width: 3px;
              height: 18px;
              background: #1156A3;
            }
          }
        }

      }

      .top_view {
        width: 100%;
        display: flex;

        .el-icon-close {
          margin-top: 19px;
          margin-right: 24px;

          font-size: 16px;
          color: #666666;
        }

        .title {
          margin-top: 18px;
          margin-left: 24px;
          margin-bottom: 18px;

          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 24px;
          flex: 1;
        }
      }
    }

    .mask {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }

  }

  .info {
    margin-top: 20px;
    background-color: white;
    padding: 30px;
    position: relative;

    .btn_view {
      position: absolute;
      right: 40px;
      top: 40px;
      width: 144px;
      height: 40px;


      background: #FFFFFF;
      border: 1px solid #1156A3;
      color: #1156A3;

      font-size: 16px;
      font-family: Microsoft YaHei, serif;
      font-weight: 400;
      line-height: 24px;
      margin-left: 10px;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 0.5;
      }
    }

    .info-name {
      font-size: 32px;
      font-weight: bold;
    }

    .info-credit {
      margin-top: 20px;
      color: #666;
    }

    .info-detail {
      background-color: #f5f5f5;
      padding: 20px;
      margin-top: 20px;

      ::v-deep .info-left-info {
        .el-descriptions__body {
          background-color: transparent;
          font-size: 16px;
        }
      }
    }
  }

  .popup_view {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;

    .content_view {
      width: 720px;
      background: #FFFFFF;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      flex-direction: column;
      align-items: center;

      .btn_user_view {
        width: 100%;
        margin-top: 35px;
        display: flex;

        .btn {
          flex: 1;
          height: 72px;
          background: #FFFFFF;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 20px;
          font-family: Microsoft YaHei, serif;
          font-weight: 400;
          line-height: 24px;

          border: 1px solid #DDDDDD;
          user-select: none;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          &.cancel {
            color: #888888;
          }

          &.confirm {
            color: #1156A3;
          }
        }
      }

      .btn_view {
        margin-top: 35px;
        margin-bottom: 30px;

        display: flex;

        .btn {
          width: 200px;
          height: 44px;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 16px;
          font-family: Microsoft YaHei, serif;
          font-weight: 400;
          line-height: 24px;

          user-select: none;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          &.cancel {
            background: #FFFFFF;
            border: 1px solid #DDDDDD;
            color: #999999;
          }

          &.confirm {
            background: #1156A3;
            color: #FFFFFF;
            margin-left: 20px;
          }
        }
      }

      .content_html {
        width: 595px;
        font-size: 18px;
        font-family: Microsoft YaHei, serif;
        font-weight: 400;
        color: #333333;
        line-height: 27px;
        margin-top: 29px;
      }

      .content {
        max-height: 70vh;
        overflow: hidden;
        overflow-y: scroll;
        -ms-overflow-style: none;


        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          -webkit-box-shadow: none;
          border-radius: 360px;
          background: rgba(197, 193, 193, 0.6);
        }

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: none;
          border-radius: 360px;
          background: transparent;
        }
      }

      .content_text {
        width: 595px;
        font-size: 18px;
        font-family: Microsoft YaHei, serif;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
        margin-top: 29px;
      }

      .title {
        font-size: 24px;
        font-family: Microsoft YaHei, serif;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
        margin-top: 40px;
      }
    }

    .mask {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .my_list_view {
    width: 100%;
    background: #fff;
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;

    ::v-deep .el-tabs__item:hover {
      color: #1156A3;
    }

    ::v-deep .el-tabs__item.is-active {
      color: #1156A3;
    }

    ::v-deep .el-tabs__active-bar {
      background: #1156A3;
    }

    ::v-deep .el-tabs__nav-wrap::after {
      background: transparent;
    }

    ::v-deep .el-tabs__nav {
      width: 100%;
    }

    ::v-deep .el-tabs__item {
      font-size: 20px;
      font-family: Microsoft YaHei, serif;
      font-weight: 400;
      width: 240px;
      height: 72px;
      background: #FFFFFF;
      line-height: 72px;
      text-align: center;
      color: #888888;
    }
  }

  .filter-box {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 30px;


    .top_title {
      display: flex;
      align-content: center;
      width: 100%;
      position: relative;
      padding: 15px 0 15px 0;

      .left_line {
        width: 4px;
        height: 20px;
        background: #1156A3;
        border-radius: 0px 4px 4px 0px;
      }

      .bottom_line {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        background: #DDDDDD;
      }

      .title_text {
        font-size: 18px;
        font-family: Microsoft YaHei, serif;
        font-weight: bold;
        color: #333333;
        line-height: 19px;
        margin-left: 11px;
      }

      .btn_text {
        font-size: 16px;
        font-family: Microsoft YaHei, serif;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin-left: auto;
        margin-right: 20px;
      }
    }

    .top_content_list {
      display: flex;
      justify-content: center;
      margin-right: 30px;

      .top_content_item {
        flex: 1;
        display: flex;
        height: 160px;
        background: #FFFFFF;
        align-items: center;
        justify-content: center;

        .re_view {
          margin-left: 19px;

          .title_name {
            font-size: 16px;
            font-family: Microsoft YaHei, serif;
            font-weight: 400;
            color: #AAAAAA;
          }

          .num {
            display: flex;
            align-items: baseline;
            white-space: nowrap;

            .company {
              margin-left: 10px;
              margin-top: 18px;
              font-size: 16px;
              font-family: Microsoft YaHei, serif;
              font-weight: 400;
              color: #AAAAAA;
            }

            .text {
              font-size: 30px;
              font-family: Microsoft YaHei, serif;
              font-weight: bold;
              color: #3988FF;
            }

          }
        }

        .icon {
          width: 54px;
          height: 51px;
          margin-left: 30px;
        }
      }
    }
  }
}
</style>
